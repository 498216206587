.totulo_info_regis{
    text-align: left;
    font-size: 40px;
    font-weight: bold;
    color: #000000;
}
.contenedor_info_regis{
    padding: 2% 2%;
}
.nombre_info_regis{
    text-align: left;
    font-size: 26px;
    color: #0fae50;
    font-weight: bold;
    line-height: 1;
    margin-bottom: 0px;
}
.puesto_info_regis{
    text-align: left;
    color: #000000;
    font-style: italic;
    line-height: 1;
}
.texto_info_regis{
    text-align: justify;
    font-size: 16px;
    color: #000000;
}

@media only screen and (max-width: 767px){
    .totulo_info_regis{
        font-size: 30px;
    }
    .contenedor_info_regis{
        padding: 2% 2%;
    }
    .nombre_info_regis{
        font-size: 26px;
    }
    .puesto_info_regis{
        text-align: left;
        color: #000000;
        font-style: italic;
        line-height: 1;
    }
    .texto_info_regis{
        font-size: 16px;
    }
}