body{
    font-family: 'Lato-Regular';
}  
a:hover{
    color: unset!important;
}
.color_naranja{
    color: #f47925;
}
.color_morado{
    color:#8e50f6;
}
.color_verde{
    color:#0fae50;
}
.back_naranja{
    background-color: #f47925;
}
.color_azul{
color: #21aaf7;
}
.back_azul{
    background-color: #21aaf7;
}
.color_gris{
    color: #666666;
}
.back_gris{
    background-color: #666666;
} 
.back_negro{
    background-color: #000000;
} 

@font-face{
    font-family: 'Lato-Bold';
    src: url('../fonts/LATO/Lato-Bold.ttf');
}
@font-face{
    font-family: 'Lato-BItalic';
    src: url('../fonts/LATO/Lato-BoldItalic.ttf');
}
@font-face{
    font-family: 'Lato-Italic';
    src: url('../fonts/LATO/Lato-Italic.ttf');
}
@font-face{
    font-family: 'Lato-Regular';
    src: url('../fonts/LATO/Lato-Regular.ttf');
}
@font-face{
    font-family: 'Abros';
    src: url('../fonts/ABROS/ABROS.ttf');
}
.lato_bold{
    font-family: 'Lato-Bold';
}
.abros{
    font-family: 'Abros';
}
