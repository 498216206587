.titles{
  text-decoration: none;
  color: #ffffff;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 14px;
}
.titles:hover{
  color: #ffffff;
}
.registro_btn{
  background-color: #0fae50;
  border-radius: 10px;
  padding: 5px 10px;
  font-weight: bold;
}
.ondemand_btn{
  background-color: #fbd000;
  border-radius: 10px;
  padding: 5px 10px;
  font-weight: bold;
  color:#000000!important;
}
.contenedor_header{
  /*background-color: rgba(0, 0, 0, 0.3);*/
  background-color: #ffffff;
    width: 100%;
    z-index:99;
    /*position: fixed;*/
}
.log_header{
  width: 250px;
}
.navbar--hidden {
  animation: desaparecer 1s;
}
@keyframes desaparecer{
  0%{display: block;}
  100%{display: none;}
}

.idioma_select{
  text-decoration: none;
  color: #00e2f3;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 14px;
  cursor: pointer;
  padding: 2%;
  margin-bottom: 0px;
}
.idioma_no{
  text-decoration: none;
  color: #ffffff;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 14px;
  cursor: pointer;
  padding: 2%;
  margin-bottom: 0px;
}

.menu_mostrar_movil{
  display:none;
}

@media only screen and (max-width: 767px){
  .titles{
    display:block;
    padding: 5px 0px;
  }
  .log_header{
    width: 100%;
  }
  .contenedor_header{
    background-color:white;
      width: 100%;
      z-index:99;
  }
  .idioma_select{
    font-size: 14px;
  }
  .idioma_no{
    font-size: 14px;
}
}
.seccion{
  padding: 2%;
  
}
.seccion:hover{
  color: #00e2f3;
}
StickyContainer{
  position: -webkit-sticky;
position: sticky;
top: 0;
}