.nombre_conferencias{
    text-align: left;
    font-weight: bold;
    font-size: 14px;
    line-height: 1;
}
.puntero_clic{
    cursor: pointer;
}
.contenedor_conferencias{
    background-color: #f1f1f1;
    padding: 4% 3%;
    margin: 2%;
}
.titulo_seccion{
    color:#8e50f6;
    text-align: center;
    font-weight: bold;
    font-size: 34px;
    line-height: 1;
}
.modal_titulo_conferencia{
    color:#8e50f6;
    text-align: center;
    font-weight: bold;
    font-size: 22px;
    line-height: 1;
}