.img_caracteristicas{
 width: 100%;
}
.texto_caracteristicas{
  text-align: center;
  font-size: 18px;
  color: #666666;
}
.titulo_acercade{
  text-align: left;
  font-size: 18px;
  color: #000000;
}
.texto_acercade{
  text-align: justify;
  font-size: 14px;
  color: #000000;
}
.img_acercade_icono{
  width: 100%;
}