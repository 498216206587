
.registro_exitoso{
    font-size:38px;
    text-align:center;
    margin-bottom: 0px;
    color:#0fae50;
    font-family: 'Abros';
}
.registro_texto_ubicacion{
    font-size:28px;
    text-align:center;
    margin-bottom: 0px;
    color:#000000;

}
.contenedor_registro_exitoso{
    border-bottom: 2px solid #000000;
}

.texto_registro_exitoso{
    font-size: 20px;
    text-align: center;
}
.ingresa_correo{
    font-size:18px;
    text-align:center;
    color:#0fae50;
}
.numero_instruccion{
    text-align:right;
    font-size: 16px;

}

.texto_instruccion{
    text-align:left;
    margin-left:10px !important;
    font-size: 16px;
}

.texto_instruccion{
}
.texto_paypal{
    font-size: 32px;
    text-align: center;
    margin-bottom: 0px;
}
.total_paypal{
    font-size: 32px;
    text-align: center;
    font-weight: bold;
    margin-bottom: 0px;
    color:#0fae50;
    font-family: 'Montserrat', sans-serif;
}
.mini_texto_paypal{
    font-size: 12px;
    text-align: center;
    margin-bottom: 0px;
    color:#0fae50;
}
.line_paypal{
    background-color: #000000;
    border: none;
    height: 4px;
}
.line_paypal_rechazado{
    background-color: black;
    border: none;
    height: 4px;
}
.texto_acceso{
    font-size:18px;
    border: 0px solid black;
    margin:0px;
}
.texto_acceso_bajo{
    font-size:18px;
    border: 1px solid black;
    margin:0px;
}
.nota_registro_exitoso{
    color:white;
    padding-top:10px;
    padding-bottom:10px;
    text-align:center;
    font-size:16px;
}

.boton_inicio{
    color:white;
    border-radius:15px;
    font-size: 20px;
    background: #0fae50;
    padding: 10px 20px;
}

.paypal-button-row.paypal-button-number-1.paypal-button-layout-vertical.paypal-button-shape-rect{
    display: none !important;
    margin-top: 1000px;
}

.contenedor_boton_ingresar{
    text-align: center;
    margin-top: 20px !important;
}

.boton_ingresar, .boton_ingresar:hover{
    color:white;
    border-radius:5px;
    font-size: 20px;
    background: #21aaf7;
    padding: 10px 20px;
}
@media only screen and (max-width: 767px){
    .texto_paypal{
        font-size: 22px;
    }
    .total_paypal{
        font-size: 28px;
    }
    .mini_texto_paypal{
        font-size: 12px;
    }
}