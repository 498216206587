.contenedor_ponente{
  background-image: url('../images/2023/banners/ies_backSpeakers.jpg');
  border-image-repeat: no-repeat;
  height: 600px;
  background-size: cover;
  padding: 2% 0%;
}
.contenedor_ponente_movil{
  background-image: url('../images/movil/06_Ponentes_IES/Ies_FondoPonentes_Movil.jpg');
  border-image-repeat: no-repeat;
  height: 780px;
  background-size: cover;
  padding: 1%;
}
.nombre_ponente{
  text-align: left;
  color: #ffffff;
  font-size: 40px;
  margin: 0px;
}
.nombre_ponente_movil{
  text-align: center;
  color: #ffffff;
  font-size: 32px;
  margin: 0px;
}
.bio_ponente{
  text-align: justify;
  color: #ffffff;
  font-size: 14px;
}
.bio_ponente_movil{
  text-align: justify;
  color: #ffffff;
  font-size: 12px;
}
/* HOVER */
.img_contenedor_ponente{
  position: relative;
  width: 100%;
}
.img_ponente {
  display: block;
  width: 100%;
  height: auto;
}

.overlay_ponente {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .5s ease;
  background-color: rgba(0,0,0,0.7);
}

.img_contenedor_ponente:hover .overlay_ponente {
  opacity: 1;
}

.text_ponente {
  color: white;
  font-size: 12px;
  line-height: 1;
  position: absolute;
  top: 50%;
  left: 20%;
  -webkit-transform: translate(-20%, -20%);
  -ms-transform: translate(-20%, -20%);
  transform: translate(-20%, -20%);
  text-align: left;
}
.nombre_hover_ponente{
  color:#ffffff;
  font-size: 14px;
  font-weight: bold;
}
.previu_hover_ponente{
  font-size: 12px;
  color: #ffffff;
}
.masinfo_hover_ponente{
  font-size: 11px;
  color: #fbd000;
  cursor: pointer;
}
.img_modal_ponente{
  border-radius: 20px;
}
.nombre_modal_ponente{
  color: #8e50f6;
  font-size: 22px;
  font-weight: bold;
  text-align: left;
}
.descripcion_modal_ponente{
  font-size: 12px;
  text-align: justify;
  line-height: 1.4;
}
.ant-modal-content{
  background-color: rgba(255,255,255,0.9);
}
@media (max-width:767px) {
  .contenedor_ponente{
    height:450px;
    background-size: cover;
    padding: 2% 0%;
  }
  .img_contenedor_ponente{
    position: relative;
    width: 100%;
  }
}