.contenedor_perfil{
    background: url(../images/02_registro/04-fondo.jpg) no-repeat;
    background-size: 100%;
}

.contenedor_secciones_perfil{
    border:1px solid cyan;
}

.contenedor_agenda_perfil{
    background-image: url('../images/01_impacDemo_home/03-fondo-programa.jpg');
}

.texto_bienvenido_perfil{
    color: black;
    font-size: 20px;
}

.seccion_perfil{
    background-color: white;
    color: black;
    padding: 15px 0px;
    cursor: pointer;
    margin: 0px;
}

.seccion_activa_perfil{
    background-color: black;
    color: white;
    padding: 15px 0px;
    margin: 0px;
}

.icono_seccion_perfil{
    font-size: 30px;
}

.nombre_seccion_perfil{
    font-size: 25px;
    text-transform: uppercase;
}

.nombre_seccion_activa_perfil{
    font-size: 25px;
    text-transform: uppercase;
}

.input_perfil{
    background-color: white !important;
    border: 1px solid lightgray !important;
}

.boton_actualizar_perfil{
    border: 1px solid white !important;
    background-color: white;
}

.imagen_actualizar_perfil{
    width: 180px;
    cursor:pointer;
}