.contenedor_titulo_patrocinadores{
  border: 2px solid #f47925;
}
.titulo_sec_patrocinadores{
  font-size: 28px;
  color: #666666;
  margin: 15px 15px;
}
.contenedor_titulo_patrocinadores_dentro{
  background-color: #666666;
}
.titulo_sec_patrocinadores_dentro{
  font-size: 28px;
  color: #ffffff;
  margin: 15px 15px;
}
.salir_patrocinador{
  color: #8e50f6;
  font-size: 18px;
  text-align: left;
  margin: 10px 0px 15px 0px;
}
.texto_patrocinadores{
  color: #666666;
  font-size: 16px;
  text-align: justify;
}
.texto_patrocinadores_link{
  color: #666666;
  font-size: 16px;
  text-align: justify;
  text-decoration: none;
}
.paddin_20{
  padding: 0px 20px;
}
.titulo_patrocinador_texto{
  color:#8e50f6;
  font-size: 36px;
  font-family: 'Abros';
}
.texto_patrocinadores_blanco{
  color: #ffffff;
  font-size: 16px;
  text-align: justify;
}
.panel_patrocinador{
  color: #666666;
  font-size: 16px;
  text-align: justify;
}
.patrocinadores_redes_img{
  width: 30px;
  margin: 10px 5px;
}
.contenedor_redes_patrocinadores{
  border-width: 0px 0px 0px 0px;
  border-color: #8e50f6;
  border-style: solid;
}
.contenedor_redes_patrocinadores_rigth{
  border-right: 0px solid #8e50f6;
}

.contenedor_video_patrocinador {
	position: relative;
	padding-bottom: 56.25%; /*panorámico*/
	padding-top: 0px;
	height: 0;
}
.contenedor_video_patrocinador iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
  height: 100%;
  z-index: 19;
}
@media only screen and (max-width:767px) {
  .salir_patrocinador{
    font-size: 22px;
  }
  .texto_patrocinadores{
    font-size: 14px;
  }
  .texto_patrocinadores_link{
    font-size: 14px;
  }
  .texto_patrocinadores_blanco{
    font-size: 14px;
  }
  .paddin_20{
    padding: 0px 0px;
  }
  .titulo_patrocinador_texto{
    font-size: 28px;
    margin-bottom: 0px;
  }
  .panel_patrocinador{
    font-size: 14px;
  }
  .patrocinadores_redes_img{
    width: 20px;
    margin: 10px 5px;
  }
  .salir_patrocinador{
    font-size: 18px;
    margin: 10px 0px 15px 0px;
  }
  .video{
    height: 400px;
  }
}