.contenedor_registro{
    
}
.contendor_banner_registro{
    background-image: url('../images/2023/banners/ies_Registro.jpg');
  border-image-repeat: no-repeat;
  height: 360px;
  background-size: cover;
  padding-top: 100px;
  background-position: center;
}
.contenedor_paquetes{
    background-color: #0fae50   ;
}
.titulo_registro{
    color:#0fae50;
    font-size: 28px;
    margin-bottom: 0px;
    text-align: left;
    font-family: 'Abros';
}
.subtitulo_registro{
    color:#0fae50;
    font-size: 26px;
    margin-bottom: 0px;
    text-align: left;
}
.inputs_registro{
    color: #0fae50!important;
    font-size: 18px!important;
    height: 40px!important;

}
.flecha_paquete{
    font-size: 40px;
    color: #ffffff!important;
}
.inputs_codigo{
    color: #21aaf7!important;
    background-color: #ffffff!important;
    border: 2px solid #ff2a4a!important;
    font-size: 22px;
    width: 70%;
}
.btn_codigo{
    background-color: #ff2a4a;
    color:#ffffff;
    font-size: 22px;
    border: 2px solid #ff2a4a;
    width: 30%;
}
.inputs_codigo_movil{
    color: #21aaf7!important;
    background-color: #ffffff!important;
    border: 2px solid #ff2a4a!important;
    font-size: 14px;
    width: 100%;
}
.btn_codigo_movil{
    background-color: #ff2a4a;
    color:#ffffff;
    font-size: 14px;
    border: 2px solid #ff2a4a;
    width: 100%;
}
.titulo_paquetes{
    color:#ffffff;
    font-size: 28px;
    margin-bottom: 0px;
    text-align: center;
    font-family: 'Abros';
}
.estilo_campos{
    color:white;
}

.ant-input{
    background-color: #f1f2f2!important;
    border: 0px solid #d9d9d9!important;

}
.ant-input-affix-wrapper{
    background-color: #f1f2f2!important;
    border: 0px solid #d9d9d9!important;
}

.nom-label{
    color:#606060!important;
    font-size: 18px!important;
}
.nom-label-cel{
    color:#538ae4!important;
    font-size: 18px!important;
}
.num-men{
    background-color: #538ae4;
    color:#ffffff;
    font-size: 18px;
}
.ant-form-item-required{
}

@media only screen and (max-width: 500px) {
    .nom-label, .nom-label-cel, .num-men{
        font-size: 12px!important;
    }
    .ant-form-item-required{
        font-size: 12px!important;
    }
   
}
.transmision{
    width: 50%;
}

.select_telefono{
    width:100px;
}

.boton_recibir_codigo{
    color: #000000 !important;
    background-color:#ffffff !important;
    border: 3px solid #08c9c9!important;
    color: #0d3159!important;
}

.boton_registrate{
    color: #538ae4 !important;
}

.boton_sin_login{
    background-color: #083280 !important;
    color: white !important;
}
.selector_radio_opcion{
    color:black;
}

.selector_radio_opcion:hover{
    color:#538ae4 !important;
}

.selector_radio_opcion:focus{
    color:#538ae4 !important;
}

.texto_label_form{
    color: #666666;
    font-size: 18px;

}
.hr_registro{
    height: 2px;
    border:none;
    background-color: #21aaf7;
}
.total_registro{
    color:#000000;
    text-align: left;
    font-size: 28px;
    font-family: 'Abros';
}
.total_precio_registro{
    color:#000000;
    text-align: left;
    font-size: 28px;
    font-family: 'Abros';
}
.sup_mxn{
    color:#000000;
    text-align: left;
    font-size: 28px;
    font-family: 'Abros';
}
.label_requerido_registro{
    color: red;
}

.boton_crear_cuenta_registro{
    width: 180px;
}

.login-form{
    background-color: #ffffff;
}
@media only screen and (max-width: 992px){
    .contendor_banner_registro{
        height: 300px;
        background-size: cover;
        padding-top: 100px;
    }
}
@media only screen and (max-width: 767px){
    .contenedor_registro{
        background-size: 100% 100%;
    }
    .titulo_registro{
        font-size: 32px;
    }
    .titulo_paquetes{
        font-size: 32px;
    }
    .total_registro{
        font-size: 25px;
    }
    .total_precio_registro{
        font-size: 25px;
    }
    .sup_mxn{
        font-size: 15px;
    }
    .contendor_banner_registro{
      height: 300px;
      background-size: cover;
      padding-top: 100px;
    }
}