.hora_agenda{
    font-size: 18px;
    color:#000;
    text-align: center;
    margin: 5px 0px;
}
.cotenedor_actividad{
    border: 0.5px solid #8e50f6;
    padding: 10% 2%;
    height: 100%;
    height: 48vh;
    align-items: center;
    display: flex!important;
    justify-content: center;
    
}
.actividad_agenda{
    font-size: 18px;
    color:#000;
    text-align: center;
    line-height: 1;
    
}
.actividad_agenda_2{
    font-size: 24px;
    color:#000;
    text-align: center;
    margin-bottom: 0px;
    
}
.lugar_agenda{
    font-size: 14px;
    color:#8e50f6;
    text-align: center;
    line-height: 1;
    
}
.persona_agenda{
    font-size: 16px;
    color:#8e50f6;
    text-align: center;
    line-height: 1;
    
}
.divPrograma{
    height: 34vh;
}
.contenedor_agenda_back{
    padding: 4% 6%;
    background-color: #f2f2f2;
}
.cuadro_agenda{
    background-color: white;
    padding: 3% 0%;
    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.2);
}
.cambios_agenda{
    text-align: center;
    font-size: 18px;
    color:black;
}
.conferencia_modal_actividad{
    color: #8e50f6;
    font-size: 22px;
    font-weight: bold;
    text-align: center;
}
.nombre_modal_actividad{
    color: #8e50f6;
    font-size: 18px;
    text-align: center;
}
.descripcion_modal_actividad{
    color: #000000;
    font-size: 14px;
    text-align: justify;
}
.masinfo_hover_actividad{
    font-size: 12px;
    color: #8e50f6;
    cursor: pointer;
    margin: 0px;
  }
@media only screen and (max-width:662px) {
    .cambios_agenda{
        font-size: 12px;
        padding: 0px 10px;
    }
    .cotenedor_actividad{
        border: 0.5px solid #8e50f6;
        padding: 10% 2%;
        height: 100%;
        height: 36vh;
        align-items: center;
        display: flex!important;
        justify-content: center;
        
    }
  }