
  @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
body{
  font-family: 'Montserrat', sans-serif;
}

.top-and-bottom{
  position: relative;
  margin-top: 30px;
  margin-bottom: 30px;
  font-size: 40px;
  padding: 10px 5px;
}
.top-and-bottom::before {
  content:'';
  position: absolute;
  top: 0;
  left:0;
  animation-name: ledboton;
  animation-duration: 3s;
  animation-timing-function: linear;
  animation-delay: 2s;
  animation-iteration-count: infinite;
  animation-direction:normal;
  background-color:#21aaf7;
  box-shadow:
    0 0 3px 3px #21aaf7;
}

@keyframes ledboton {
  0%   {left:0%; top:0%;width:60px;height: 2px;}
  25%  {left:100%; top:0%;width:2px;height: 2px;}
  26%  {left:100%; top:0%;width:2px;height: 20px;}
  
  50%  {left:100%; top:100%;width:2px;height: 2px;}
  51%  {left:100%; top:100%;width:2px;height: 2px;}
  
  74%  {left:0%; top:100%;width:60px;height: 2px;}
  75%  {left:0%; top:100%;width:2px;height: 2px;}
  76%  {left:0%; top:100%;width:2px;height: 2px;}
  
  100% {left:0%; top:0%;width:2px;height: 20px;}
}

.content_wsp_fixed{
  position: fixed;
  z-index: 99;
  bottom: 20%;
  right: 0px;
}
.sticky_wsp{
  width: 120px;
}

.tituloBackstage{
  font-size: 40px;
  font-weight: bold;
  text-align: center;
  color: #21aaf7;
}
.img_ponente_backstage{
  width: 60%;
}
.nombre_backtage{
  color:#000000;
  font-size: 12px;
  text-align: left;
  margin: 0px;
  font-weight: bold;
}
.ponencia_backtage{
  color:#000000;
  font-size: 12px;
  text-align: center;
  margin: 0px;
  font-weight: bold;
}
.img_ponente_backstage_movil{
  width: 90%;
}
@media (max-width:767px) {
  .img_ponente_backstage{
    width: 90%;
  }
  .tituloBackstage{
    font-size: 28px;
  }
  .top-and-bottom{
    position: initial;
    margin-top: initial;
    margin-bottom: initial;
    -webkit-text-stroke: initial;
  }
  .top-and-bottom::before {
    content:'';
    position: initial;
    animation-name: none;
  }
  .img_ponente_backstage_movil{
    width: 100%;
  }
}